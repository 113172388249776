#root {
  height: 100vh;
}

body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body *::-webkit-scrollbar-track {
  background: transparent;
}

body *::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  background-clip: padding-box;

}